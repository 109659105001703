import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modalproject',
  templateUrl: './modalproject.component.html',
  styleUrls: ['./modalproject.component.css']
})

export class ModalprojectComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalprojectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
     console.log(this.data);
  }

}
