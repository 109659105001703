import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ProjectsComponent } from './projects/projects.component';
import { ContactComponent } from './contact/contact.component';
import { AppRoutesModule } from './routes/app-routes.module';

import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { GoogleMapsModule } from '@angular/google-maps'
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ModalprojectComponent } from './modalproject/modalproject.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatIconModule} from '@angular/material/icon';

import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';

import {environment} from '../environments/environment';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.serverUrl // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>
    </span>
    `,
  },
  content:{
    message: 'By using our site, you acknowledge that you have read and understand our ',

    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: environment.serverUrl + '/cookiepolicy'
  },
  theme: 'edgeless',
  type: 'opt-out'
};

@NgModule({
    declarations: [
        AppComponent,
        ProjectsComponent,
        ContactComponent,
        HomeComponent,
        AboutComponent,
        ModalprojectComponent,
        CookiepolicyComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        RouterModule,
        AppRoutesModule,
        HttpClientModule,
        MatFormFieldModule,
        FormsModule,
        MatDialogModule,
        BrowserAnimationsModule,
        DragDropModule,
        MatIconModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        GoogleMapsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
