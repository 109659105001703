export class Project  {

    id: number;
    heading: string;
    shortNarrative: string;
    backgroundNarrative: string;
    ourhelpNarrative: string;
    techNarrative: string;


    constructor(
            id: number,
            heading: string,
            shortNarrative: string,
            backgroundNarrative: string,
            ourhelpNarrative: string,
            techNarrative: string
            ) {
        this.id = id;
        this.heading = heading;
        this.shortNarrative = shortNarrative;
        this.backgroundNarrative = backgroundNarrative;
        this.ourhelpNarrative = ourhelpNarrative;
        this.techNarrative = techNarrative;
    }
}
