import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalprojectComponent} from '../modalproject/modalproject.component';
import { MatDialog } from '@angular/material/dialog';
import { Project} from './project';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  title = 'Project success stories in the London Insurance market and more.';

  projectList: Project[] = [];

  constructor(private titleService: Title, private metaService: Meta,
    private route: ActivatedRoute,
    private location: Location,
    public dialog: MatDialog ) {

  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.updateTag({name: 'keywords', content: "data migration, legacy replacement, Lloyd's, binder management, bordereaux, REST, web services, ACORD, EDI messaging, single-sign-on, SSO, ETL, Java EE, Talend, Tomcat, JBoss, Alfresco, Angular, Linux, WildFly, Activiti, OAuth, SAML, Keycloak, RedHat SSO, Twilio, AuthO, Azure"});
    this.metaService.updateTag({name: 'description', content: "Read our client success stories covering the Lloyd's, London and global insurance markets.  We have implemented countless successful solutions including for data transformation, data migration, collaboration platforms, reinsurance systems and messaging solutions."});
    this.metaService.updateTag({name: 'robots', content: 'index, follow'})

    this.projectList.push(new Project(0, 'Data migration and legacy tech replacement project for a global insurance company'
        // tslint:disable-next-line: max-line-length
        , 'Our client, a global insurance management specialist, had purchased a large US insurance portfolio.  The existing technology employed by the US business was outdated, expensive and poorly supported.  The lack of a modern technology platform had led the US portfolio to employ arcane and...'
        // tslint:disable-next-line: max-line-length
        , 'Our client, a global insurance management specialist, had purchased a large US insurance portfolio.  \r\n\r\nThe existing technology employed by the US business was outdated, expensive and poorly supported.  The lack of a modern technology platform had led the US portfolio to employ arcane and complex, often manual, procedures for claim handling, ledger processes and reinsurance recovery.'
        // tslint:disable-next-line: max-line-length
        , 'Perspective were employed to migrate the US portfolio to an alternative technology platform.  \r\n\r\nThis involved analysis of all the business operations in collaboration with the management team in the US.  Various functional gaps were identified in the destination platform requiring multiple development projects.  These were developed by Perspective.  \r\n\r\nAlongside the functional development, the insurance data was migrated from the legacy system.  \r\n\r\nPerspective developed a repeatable and audited process for migrating the policy, claim, premium, reinsurance and accounting data.'
        , 'ETL, Java EE, Talend, Tomcat, JBoss' ));

    this.projectList.push(new Project(1, 'Content Management Technology review for a media company'
        // tslint:disable-next-line: max-line-length
        , 'A leading media production company had built their own content management system for storing and managing the production of various media types.  The system had been maintained by a small, internal team.  Whilst much of the system was created in modern and appropriate technology, the...'
        // tslint:disable-next-line: max-line-length
        , 'A leading media production company had built their own content management system for storing and managing the production of various media types.  The system had been maintained by a small, internal team.  Whilst much of the system was created in modern and appropriate technology, the user interface (UX)components were written in a technology that was not being taken forward.  Hence, a technical review was required to determine the best way forward.'
        // tslint:disable-next-line: max-line-length
        , "We undertook a full technical review which included the setup of our client's system software on our own cloud infrastructure.  \r\n\r\nWe made recommendations for system upgrades in certain areas.  For the UX, we recommended adoption of a modern framework such as Angular.  We understand that our review was very helpful and our recommendations were adopted in full."
        , 'Alfresco, Angular, Linux' ));

    this.projectList.push(new Project(2, 'Collaboration platform for managing delegated underwriting'
        // tslint:disable-next-line: max-line-length
        , "A Lloyd's syndicate was looking to implement a collaboration platform for managing their delegated authority arrangements.  The underwriting partners had clear and unique requirements for oversight of their..."
        // tslint:disable-next-line: max-line-length
        , "A Lloyd's syndicate was looking to implement a collaboration platform for managing their delegated authority arrangements.  The underwriting partners had clear and unique requirements for oversight of their binding arrangements.  They were clear from the start that a technology-led approach would be central for the operation of their syndicate."
        // tslint:disable-next-line: max-line-length
        , "Perspective were commissioned, initially to lead a technology selection process.  This was quickly followed by a rapid build of a viable initial implementation.  \r\n\r\nThe platform now includes Enterprise Content Management, Business Process Management and Management Information components - all delivered in a secure and controlled environment.  \r\n\r\nThe platform is now used by hundreds of individuals across the syndicates' own team, their agency partners, brokers, regulators, claim specialists, lawyers and more."
        , null ));

    this.projectList.push(new Project(3, 'Insurance platform resulting in reduced costs and ease of consolidated reporting'
        // tslint:disable-next-line: max-line-length
        , 'Our client, a long-established provider of services to the run-off insurance sector, had been operating a wide number of insurance portfolios on disparate systems, usually those that had been used for live underwriting.  This was...'
        // tslint:disable-next-line: max-line-length
        , "Our client, a long-established provider of services to the run-off insurance sector, had been operating a wide number of insurance portfolios on disparate systems, usually those that had been used for 'live' underwriting.  This was costly in licensing and duplicative in support effort.  Importantly, it also meant that understanding (re)insurer counter-party positions across their portfolios involved manual, error prone steps."
        // tslint:disable-next-line: max-line-length
        , 'Perspective designed, authored and implemented a full insurance platform.  \r\n\r\nThe new system was designed with easy migration in mind.  Open-source tooling ensured that the platform would be supportable in the long term.  \r\n\r\nThe implementation was very successful and was subsequently extended to UK and European portfolios.'
        , 'Java EE, JBoss, WildFly' ));

    this.projectList.push(new Project(4, "Data mining project for a Lloyd's Managing Agent to satisfy Lloyd's Brussels setup"
        // tslint:disable-next-line: max-line-length
        , "Our client, a Lloyd's Managing Agent, were required by their regulators to provide information regarding historical policies written involving insureds based within the EU.  This was part of the Brexit planning and setup.."
        // tslint:disable-next-line: max-line-length
        , "Our client, a Lloyd's Managing Agent, were required by their regulators to provide information regarding historical policies written involving insureds based within the EU.  This was part of the Brexit planning and setup of Lloyd's Brussels."
        // tslint:disable-next-line: max-line-length
        , "The client required help with data mining from a wide set of disparate sources.  \r\n\r\nPerspective undertook to map the requirements from the regulator to the various policy data sources available at the Managing Agent.  Finally, queries were authored to extract and provide the data.  \r\n\r\nThis was a simple project and was completed in a matter of a few weeks."
        , "Access, SQL Server, Lloyd's EDI messaging" ));

    this.projectList.push(new Project(5, 'Innovative Binder Management service for an insurance service company'
        // tslint:disable-next-line: max-line-length
        , 'Our client is a leading provider of services to the insurance industry.  One of their services offered to the market is the collection, validation and reporting of bordereaux data for London Market insurance companies.  The client had...'
        // tslint:disable-next-line: max-line-length
        , 'Our client is a leading provider of services to the insurance industry.  One of their services offered to the market is the collection, validation and reporting of bordereaux data for London Market insurance companies.  The client had been using a home-built system for some years.  This system was not investable in the long-term due to outdated and unsupported technology.'
        // tslint:disable-next-line: max-line-length
        , "As the client had ruled out the off-the-shelf market bordereaux systems, Perspective were commissioned to redesign and replace the in-house system.  Perspective recommended a system based upon open-source and standards-based technology.  Alfresco was chosen as the Content Management platform, Talend was utilised for its Data Transformation componentry and Activiti as the Business Process Management tool.  \r\n\r\nPerspective authored their own software to interface the Alfresco and Talend platforms.  Using this interface, transformation and validation of bordereaux could be achieved seamlessly directly from the Alfresco interface.  The Alfresco 'Share' interface was extended to support screens for Binder Management.  \r\n\r\nThe resulting system is a good example of 'best of breed' open source tooling being used to create a bespoke solution for a customer at reasonable cost.  \r\n\r\nWithin 2 months a working module of the system was in operation.  \r\n\r\nThe final system was extremely successful and directly enabled the client to grow their business substantially."
        , 'Alfresco, Talend, Activiti, REST, web services' ));

    this.projectList.push(new Project(6, 'Platform for handling ACORD standard bordereaux using web services'
        // tslint:disable-next-line: max-line-length
        , 'Many London Market companies operate large delegated portfolios via contracted agency companies.  It is common for the agencies to return risk, premium and claim information to the London Market companies via spreadsheets and email.  The management of this data...'
        // tslint:disable-next-line: max-line-length
        , 'Many London Market companies operate large delegated portfolios via contracted agency companies.  It is common for the agencies to return risk, premium and claim information to the London Market companies via spreadsheets and email.  The management of this data at the Carriers has become a huge headache for the market.'
        // tslint:disable-next-line: max-line-length
        , "The Lloyd's Market created a working group to trial the use of ACORD messaging.  This project, known as 'Project Tomorrow', was supported by several Managing Agents and an Australian coverholder.  \r\n\r\nPerspective's messaging solution was used by four Managing Agents for the duration of the pilot.  \r\n\r\nOur software successfully received the messages from the Australian insurer and transformed the data for easy receipt into Bordereaux Management systems used by London Market entities."
        , 'Talend, Alfresco, XML, ACORD, SOAP, web services, REST' ));


    this.projectList.push(new Project(7, 'Data transformation project for one of the largest UK BPO companies'
        // tslint:disable-next-line: max-line-length
        , "One of the largest UK BPO companies had been commissioned to help create a new insurance pool that provides flood cover for UK domestic properties.  Part of the implementation required the aggregation and analysis of policy, claim and premium data for reinsurance..."
        // tslint:disable-next-line: max-line-length
        , "One of the largest UK BPO companies had been commissioned to help create a new insurance pool that provides flood cover for UK domestic properties.  Part of the implementation required the aggregation and analysis of policy, claim and premium data for reinsurance purposes.  \r\n\r\nThe BPO company had chosen an existing reinsurance platform that used Lloyd's data standards for policy, claim and premium data.  The challenge was to transform the flood policy data into Lloyd's standards formats."
        // tslint:disable-next-line: max-line-length
        , "Using innovative open-source technology Perspective created a platform for transformation of 'spreadsheet' data into standards-based data allowing seamless integration and fault-free processing.\r\n\r\nThe solution translates various spreadsheet formats into Lloyd's Market standard messages for processing by established Lloyd's insurance systems."
        , "Talend, XML, EDI, Lloyd's London Market messaging"));


    this.projectList.push(new Project(8, 'Innovative database technology review for an Investment Company'
        // tslint:disable-next-line: max-line-length
        , 'Perspective were commissioned by an investment company to review a database technology developed by by an innovative start-up.  The start-up had built a new database technology which has particular relevance in Artificial Intelligence and Machine Learning...'
        // tslint:disable-next-line: max-line-length
        , null
        // tslint:disable-next-line: max-line-length
        , "Perspective were commissioned by an investment company to review a database technology developed by an innovative start-up.  \r\n\r\nThe start-up had built a new database technology which has particular relevance in Artificial Intelligence and Machine Learning areas.  Perspective reviewed the technology, the market opportunity and the start-up's strategy.  \r\n\r\nUsing our report, the investment company was able to get understandable insight into a highly specialised and technical realm.  We understand our final report was very useful in determining their investment decision."
        , null ));


    this.projectList.push(new Project(9, "Adding multi-factor authentication to Lloyd's Syndicate system"
        // tslint:disable-next-line: max-line-length
        , 'Multi-factor authentication has become a key tool to ensure the security of modern, web-based systems.  Many enterprises are moving away from user/password access.  The implementation of new authentication mechanisms for existing systems...'
        // tslint:disable-next-line: max-line-length
        , "Multi-factor authentication has become a key tool to ensure the security of modern, web-based systems.  Many enterprises are moving away from user/password access.  The implementation of new authentication mechanisms for existing systems can raise significant technical challenges.  \r\n\r\nOur client, a Lloyd's syndicate has a successful Coverholder collaboration platform widely used by themselves and their business partners around the world.  To ensure the very best security of customer data, the syndicate commissioned work on upgrading the authentication mechanisms used by their systems."
        // tslint:disable-next-line: max-line-length
        , 'Perspective initially demonstrated how widely used componentry could be bolted on to their existing systems in a robust and secure way.  The new componentry allows various extra security features to be used including two-factor authentication via mobile-delivered one-time passwords or via SMS messages.  \r\n\r\nOur proposed solution was adopted and this solution is now being rolled out.'
        , 'OAuth, SAML, Keycloak, RedHat SSO, Twilio, AuthO, Azure, REST' ));

  }

  openProjectDetail(id: number) {
    // Prepare data for dialog
    var dialogData = {heading: this.projectList[id].heading,
      backgroundNarrative: this.projectList[id].backgroundNarrative,
      ourhelpNarrative: this.projectList[id].ourhelpNarrative,
      techNarrative: this.projectList[id].techNarrative};

    // Open a dialog for the project detail
    let dialogRef = this.dialog.open( ModalprojectComponent, {
        data: dialogData, panelClass: 'custom-dialog-container'
    } );
  }
}
