import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  title = 'Contact Us - we are based in the heart of the London Insurance Market.';

  constructor(private titleService: Title, private metaService: Meta,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  mapOptions: google.maps.MapOptions = {
    center: { lat: 51.5133, lng: -0.0759 },
    zoom : 14
  }
  marker = {
    position: { lat: 51.5133, lng: -0.0759 },
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.metaService.updateTag({name: 'keywords', content: 'Edward Wichmann, Dave Wichmann, Spring Boot, Azure, Alfresco, Talend, Keycloak, Talend, Angular, WildFly, Java EE'});
    this.metaService.updateTag({name: 'description', content: 'We deliver digital solutions based upon open standards and platforms.  We are based at Minories, London, UK - in the heart of the London Insurance Market.'});
    this.metaService.updateTag({name: 'robots', content: 'index, follow'})
  }
}
