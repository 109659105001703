import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  title = 'We deliver digital solutions based upon open standards and platforms.';

  constructor(private titleService: Title, private metaService: Meta)  { }

  onClick(element) {
    (document.getElementById('img01') as HTMLImageElement).src = (element as HTMLImageElement).src;
    document.getElementById('modal01').style.display = 'block';
    // tslint:disable-next-line: prefer-const
    var captionText = document.getElementById('caption');
    captionText.innerHTML = element.alt;
  }


  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.metaService.updateTag({name: 'keywords', content: 'Edward Wichmann, Dave Wichmann, Spring Boot, Azure, Alfresco, Talend, Keycloak, Talend, Angular, WildFly, Java EE'});
    this.metaService.updateTag({name: 'description', content: 'We deliver digital solutions based upon open standards and platforms.  We are based in London.  We can help build, support and develop your digital solutions.  Our consultants can work independently or as part of your own project team.  You can make the right technology choices with our help.  These are some of our favourite tools of choice: Spring Boot, Azure, Alfresco, Talend, Keycloak, Talend, Angular, WildFly, Java EE'});
    this.metaService.updateTag({name: 'robots', content: 'index, follow'})
  }

}
