import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title = 'Perspective Technologies deliver digital solutions based upon open standards and platforms.';

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.updateTag({name: 'keywords', content: 'open solutions, financial services, digital, London, web, development, application, support, help, consultant, consultancy'});
    this.metaService.updateTag({name: 'description', content: 'We deliver digital solutions based upon open standards and platforms.  We are based in London.'});
    this.metaService.updateTag({name: 'robots', content: 'index, follow'})
  }


}
